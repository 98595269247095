import React from "react";
import { graphql } from "gatsby";

import { Container, SEOPage } from "../components";
import { Grid3, Grid4 } from "../components/css";

import HeroSearch from "cre-plugin/src/components/search";
import { Featured } from "cre-plugin/src/components/propertyComponents";

import Portfolio from "../components/portfolio";

export default function Home({ data }) {

  //On page SEO
  const pageSEO = {
    title: "Home",
    url: `/`,
    type: "page"
  }

  return (
    <Container page="content">

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        title={pageSEO.title}
        url={pageSEO.url}
        type={pageSEO.type}
      />

      <div style={{ display: "grid", alignItems: "center", backgroundImage: "url(https://res.cloudinary.com/bridgeport/image/upload/e_brightness:-20/1bdf9421-02f3-4853-af75-df9c68346bcd-1_o44m6m.jpg)", minHeight: 400, width: "100%", backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>

        <HeroSearch type="hero" state={{
          searchState: {
            sortBy: "ToLet",
            page: 1,
            range: {
              max_gla: {
                min: 0,
                max: 10000000000
              },
              min_gla: {
                min: 0,
                max: 10000000000
              }
            },
            refinementList: {
              property_category: [],
              suburb_cluster: []
            }
          }
        }} />

      </div>

      <div style={{ maxWidth: "1200px", margin: "1em auto", padding: "2rem 0" }}>

        <h2 style={{ marginTop: 0 }}>About us</h2>

        <p>Bridgeport Property Administration was formed to address the ever growing demand in the market for a transparent, professional property management company with a focus on tenant relationship management and hands on facilities and property management.</p>
        <p>Founded in 2002 Bridgeport Property Administration has been active in the property market for the last 16 years. The portfolio currently under management is in the region of R1 billion. We manage and represent over ten different landlord companies across the commercial, industrial and retail sectors.</p>

      </div>

      <div style={{ padding: "2rem 0", backgroundImage: "url(https://res.cloudinary.com/bridgeport/image/upload/e_brightness:-20/1bdf9421-02f3-4853-af75-df9c68346bcd-1_o44m6m.jpg)", backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>

        <Featured data={data} />

      </div>
      <div style={{ background: "#0b203b", color: "white", margin: "0" }}>
        <div style={{ maxWidth: "1200px", padding: "3rem 0", margin: "auto" }}>

          <h2 style={{ marginTop: 0 }}>Our services</h2>

          <Grid3>
            <div className="servicesSquare">Property and facilities management</div>
            <div className="servicesSquare">Accounting and reporting</div>
            <div className="servicesSquare">Marketing</div>
            <div className="servicesSquare">Asset management</div>
            <div className="servicesSquare">Lease negotiation</div>
          </Grid3>

        </div>

      </div>

      <div style={{ background: "#071526", color: "white", margin: "0" }}>
        <div style={{ maxWidth: "1200px", padding: "3rem 0", margin: "auto" }}>

          <h2 style={{ marginTop: 0 }}>Managed properties</h2>

          <Portfolio />

        </div>

      </div>

    </Container >
  )
}

export const query = graphql`
  query {
    featuredToLet: allD9PropertyToLet(filter: {property_featured: {eq: 1}}) {
      nodes {
        objectID
        gmaven_mapped_key
        property_gmaven_key
        property_name
        street_address
        suburb
        city
        province
        country
        cluster
        latitude
        longitude
        marketing {
          property_marketing_description
          unit_marketing_heading
          unit_marketing_description
        }
        total_property_gla
        total_erf_extent
        property_category
        property_sub_category
        unit_category
        secondary_category
        property_featured
        best_image
        status
        status_date
        web_ref
        gross_price
        net_price
        available_type
        available_date
        unit_id
        complex_space
        unit_responsibility {
          gmaven_contact_key
          cell_number
          email
          name
          image
          role
        }
        min_gla
        max_gla
        sub_divisible
        this_unit_can_be_leased_by_itself
        combinable
        property_video
        video
        property_virtual_tour
        virtual_tour
        property_update_date
        unit_update_date
        property_images {
          image_path_url
          type
        }
        office_features {
          has_aircon
          no_floors
          lift_count
          lift_cores
          has_internet
          internet_provider
        }
        industrial_features {
          power_phase
          power_output
          power_unit
          has_yard
          height_to_eaves
          lux_level
          lux_description
          gantry_cranes
          floor_load_capacity
        }
        parking {
          property_parking
          property_parking_ratio
          property_parking_open
          property_parking_basement
          property_parking_covered
          property_parking_shade
          property_parking_tandem
        }
        general_features {
          property_naming_rights
          security_guards
          security_hours
          security_responsibility
          has_security
          security_infrustructure
          green_certification
          green_certification_rating
          has_generators
          solar
          backup_water_supply
          canteen
          property_kitchenette
          gym
          boardroom
          showers
        }
      }
    }
    featuredForSale: allD9PropertyForSale(filter: {property_featured: {eq: 1}}) {
      nodes {
        objectID
        gmaven_mapped_key
        property_name
        street_address
        suburb
        city
        province
        country
        cluster
        latitude
        longitude
        best_image
        _typename
        property_type
        unit_id
        min_gla
        max_gla
        total_property_gla
        total_erf_extent
        property_category
        secondary_category
        status
        status_date
        web_ref
        property_featured
        available_type
        available_date
        gross_price
        video
        virtual_tour
        complex_space
        update_date
      }
    }
  }
`